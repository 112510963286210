import React from 'react'
import styled from "styled-components"
import BookBlock from '../components/BookBlock'
import { injectIntl } from "gatsby-plugin-intl"


const HorizontalEvent = ({data}) => {

    
 

    let BookContent = data;
    const {edges: bookData} = BookContent.allContentfulBook;
    return (
        <SectionEvent>
          <EventCenter>
            {bookData.map((event) => {
                const {title} = event.node;
                const {image} = event.node;
                const {content} = event.node.content;
                const {id} = event.node;
             return <BookBlock  key ={id} image = {image} description = {content} title = {title}/>
            })}
          </EventCenter>
        </SectionEvent>
      )
    }
    
    const SectionEvent = styled.section`
      padding-bottom: 5rem;
    `
    const EventCenter = styled.section`
      width: 80vw;
      left: 20%;
      max-width: 1170px;
      margin: 0 auto;
      align-items: center; 
    `
    // const Button = styled.button`
    //   display: block;
    //   width: 14rem;
    //   height: 3.1rem;
    //   text-align: center;
    //   margin: 0 auto;
    //   margin: 3rem auto 0;
    // `

    
    
    export default injectIntl(HorizontalEvent)