import React from "react"
import { graphql } from "gatsby"
import BookList from "../constants/BookList"
import Layout from "../components/Layout.js"
import styled from "styled-components"
import Hero from "../components/HeroTwo"
import { injectIntl} from "gatsby-plugin-intl"



const libros = ({data, intl}) => {


  let libTitle = ''
  const {node_locale} = data.allContentfulBook.edges[0].node;
  node_locale === "en" ? libTitle = "Books" : libTitle = "Libros";

  return (
    <Layout>
      <Hero content = {libTitle} image = {data.file}/>
      <Container>
        <Wrapper>
          <BookList data = {data}/>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  padding-top: 8.5rem;
  background: ${({ theme }) => theme.colorGrey10};
`

const Wrapper = styled.section`
  background: ${({ theme }) => theme.colorGrey10};
`


export const data = graphql`
  query getBook($locale: String) {
    allContentfulBook(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          title
          image {
            fluid(quality: 80) {
              src
            }
          }
          content {
            content
          }
          id
          node_locale
        }
      }
    }
    file(relativePath: {eq: "books1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default injectIntl(libros);

