import React from "react"
import styled from "styled-components"
import Image from 'gatsby-image'


const HeroTwo = ({ blog, content, image, subtitle }) => {
  return (
    <HeroHeader>
      <HeroCenter>
        <HeroImageWrapper>
            {blog ? <HeroImage fluid = {image}> </HeroImage>: <HeroImage fluid={image.childImageSharp.fluid}></HeroImage> }   
        </HeroImageWrapper>
        <HeroTextWrapper>
          <HeroTitle>
            <Name>{content}</Name>
            <Underline></Underline>
          </HeroTitle>
          <TextDescriptionWrapper>
              <TextDescription>
                {subtitle}
              </TextDescription>
          </TextDescriptionWrapper>      
        </HeroTextWrapper>
      </HeroCenter>
    </HeroHeader>
  )
}



const HeroTextWrapper = styled.div`
  z-index: 10;
  height: 61.0vh;
  width: 100%;
  position: relative;
  text-align: left;
  top:-85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const HeroTitle = styled.div`
  height: 10rem;
  width: fit-content;
  align-items: left;
  justify-content: left;  /* Add this line to align child elements to the left */
  left: 10%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Underline = styled.section`
  width:10rem;
  max-width: 10rem;
  height: 0.25rem;
  justify-content: left;
  position: relative;
  background: ${({ theme }) => theme.colorPrimary5};
  z-index: 40;
  display: inline;
  left:1%;

`


const TextDescription = styled.h3`
  width: 100%;
  margin: 0 auto;
  align-items: left;
  color: white;
  color: ${({ theme }) => theme.colorWhite};
  max-width:60rem;
`

const TextDescriptionWrapper = styled.div`
    justify-content: left;  /* Add this line to align child elements to the left */
    height: 10rem;
    margin-right:5rem;
    width: fit-content;
    max-width: 50rem;
    align-items: left;
    text-align: left;
    left: 10%;
    position: relative;

`

const HeroImageWrapper = styled.div`
  z-index: 1;
  height: 80vh;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  @media screen and (max-width: 799px) {
    height: 65vh;
  }
`
const Name = styled.h1`
  z-index: 10000;
  color: ${({ theme }) => theme.colorWhite};
 

 
 
`



const HeroHeader = styled.header`
  height: 80vh;
  margin: 0 auto;
  justify-content: center;
  background: ${({ theme }) => theme.background};
  
  @media screen and (max-width: 799px) {
    height: 65vh;
  }
`
const HeroCenter = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 799px) {
    height: 65vh;
  }
`

const HeroImage = styled(Image)`
  height: 80vh;
  width: 100%;
  z-index: 1;
  filter: brightness(60%);

  @media screen and (max-width: 799px) {
    height: 65vh;
  }

`






export default HeroTwo